import { AntdSortOrder, TableParams } from '../lib/Table';
import { IFilterOption, ISelectedFilterOption } from '../views/interfaces';
import { EFulFillmentStatus } from './../views/enums';

export const isObjectEmpty = (obj: object) => {
  return (
    typeof obj === 'undefined' || obj === null || Object.keys(obj).length === 0
  );
};

export const getAscDesc = (order?: AntdSortOrder) => {
  if (order === 'ascend') {
    return 'asc';
  } else if (order === 'descend') {
    return 'desc';
  } else {
    return 'asc';
  }
};

export const sort = (
  sortOrder?: AntdSortOrder,
  sortBy?: string
): { by: string; order: string } | null => {
  if (Array.isArray(sortOrder)) {
    sortOrder = sortOrder[0];
  }
  if (sortOrder && sortBy) {
    return {
      by: sortBy.toLowerCase(),
      order: getAscDesc(sortOrder),
    };
  } else {
    return null;
  }
};

export const limitOffset = (tableParams: TableParams) => {
  return {
    offset:
      ((tableParams.pagination?.current as number) - 1) *
      (tableParams.pagination?.pageSize as number),
    limit: tableParams.pagination?.pageSize as number,
  };
};

export const getFilterOptions = (
  data: any = [],
  key?: string
): IFilterOption[] => {
  return data.map((item: IFilterOption) => {
    return {
      ...item,
      value:
        key &&
        (
          [EFulFillmentStatus.Internal, EFulFillmentStatus.External] as string[]
        ).includes(key)
          ? `${item.value}:${key}`
          : item.value,
      tempChecked: false,
      isUpdated: false,
      children: item.children
        ? getFilterOptions(item.children, item.value)
        : undefined,
    } as IFilterOption;
  });
};

export const updateFilterObject = (
  apply: boolean = false,
  data: IFilterOption[] = [],
  clear: boolean = false
): IFilterOption[] => {
  return data.map((item: IFilterOption) => {
    return {
      ...item,
      checked: clear
        ? false
        : apply && item.isUpdated
        ? item.tempChecked
        : item.checked,
      tempChecked: false,
      isUpdated: false,
      children: item.children
        ? updateFilterObject(apply, item.children)
        : undefined,
    } as IFilterOption;
  });
};

export const getSelectedFilter = (data: IFilterOption[]) => {
  return data
    .filter((item) => item.checked)
    .map((item) => {
      return {
        id: item.value,
        name: item.label,
      } as ISelectedFilterOption;
    });
};

export const sortCheckUncheckedFilter = (
  data: IFilterOption[] = [],
  isAdvanced: boolean = false
) => {
  const checkedFilter: IFilterOption[] = [];
  const uncheckedFilter: IFilterOption[] = [];
  data.forEach((item) => {
    if (isAdvanced ? item.checked && !item.isUpdated : item.checked) {
      checkedFilter.push(item);
    } else {
      uncheckedFilter.push(item);
    }
  });
  return [
    ...checkedFilter.sort(sortFilterOptions),
    ...uncheckedFilter.sort(sortFilterOptions),
  ];
};

export const sortFilterOptions = (
  first: IFilterOption,
  second: IFilterOption
) => first?.label?.localeCompare(second?.label);
